html {
  --dark-blue: #010508
}
body {
  background: linear-gradient(45deg, #417493, #13212a);
  font-family: Roboto, sans-serif;
}

.App {
  text-align: center;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2em 1em;
  box-sizing: border-box;
  width: 100vw;
  min-height: 2em;
  color: #fafafa;
  background: var(--dark-blue);
  flex-basis:auto;
}
