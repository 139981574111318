.c__slider {
  display: flex;
  flex-direction: column;
  width: 100vw;
  box-sizing: border-box;
  margin: auto;
  background: linear-gradient(45deg, #417493, #13212a);
}

.c__slide {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-shadow: 0 3px 6px #000e;
  background-color: linear-gradient(45deg, #417493, #13212a);
  /* background-size: 100%; */
  /* background-size: auto; */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.slide {
  overflow: hidden;
  height: 100vh;
  display: flex;
}
.c__slide-announcement {
  font-size: 22px;
  margin: 1em;
}

.c__slide-body {
  color: white;
  font-size: 40px;
  padding: 0em 5em;
}

.c__slide-item {
  margin: 0 0.5em;
}

.c__slide-item span {
    font-weight: bold;
}
